<template>
  <div>
    <div v-if="this.$store.state.protection.protectionQuoteResult.length !== 0">
      <b-container fluid class="p-0 mb-3">
        <b-row class="m-0">
          <b-col align="center" sm="12" md="7" lg="8" xl="9"></b-col>
          <b-col align="right" class="p-0" sm="12" md="5" lg="4" xl="3">
            <b-form-select v-model="sortData" :options="sortOptions"></b-form-select>
          </b-col>
        </b-row>
      </b-container>
      <div>
        <template v-for="(product, idx) in products">
          <quote-result-item 
            v-bind:key="idx"
            :product.sync="products[idx]"
            v-bind:class="{'translucent-result': !product.isActive && isViewMode }"
            v-on:update:isViewMode="updateViewMode"
            v-on:update:isEditMode="updateEditMode"
          />
        </template>
      </div>
    </div>
    <div v-if="this.$store.state.protection.protectionQuoteResult.length === 0" class="result-item-border p-5 round-border" v-bind:style="$store.getters.moreLessBorder" >
      <div v-bind:style="$store.getters.elementColourBlockText" class="no-result-found">No results found.</div>
    </div>
  </div>
</template>

<script>
import QuoteResultItem from '@/view/pages/protection/quote-results/QuoteResultItem.vue'

export default {
  name: 'result-list',
  props: ['isViewMode','isEditMode'],
  data(){
    return {
      sortOptions: [
        { value: 1, text: "Ascending Price" },
        { value: 2, text: "Descending Price" },
        { value: 3, text: "Insurer A-Z" },
        { value: 4, text: "Insurer Z-A" },
      ]
    }
  },
  components: {
    QuoteResultItem
  },
  computed: {
    products: {
      get() {
        return this.$store.state.protection.protectionQuoteResult;
      }
    },
    sortData: {
      get(){
        var sortBy = this.$store.state.protection.sortBy;
        var sortOrder = this.$store.state.protection.sortOrder;

        if(sortOrder == 'asc' && sortBy == 'price'){
          return 1;
        } else if(sortOrder == 'desc' && sortBy == 'price'){
          return 2;
        } else if(sortOrder == 'asc' && sortBy == 'insurer'){
          return 3;
        } else {
          return 4
        }
      },
      set(value){
        if(value == 1 || value == 3){
          this.$store.commit("updateSortOrder","asc");
        } else {
          this.$store.commit("updateSortOrder","desc");
        }

        if(value == 1 || value == 2){
          this.$store.commit("updateSortBy","price");
        } else {
          this.$store.commit("updateSortBy","insurer");
        }

        this.$store.commit("sortQuotes");
      }
    }
  },
  methods: {
    updateViewMode: function(viewMode){
      console.log("triggered update");
      this.$emit('update:isViewMode', viewMode);
    },
    updateEditMode: function(editMode){
      this.$emit('update:isEditMode', editMode);
    }
  }
}
</script>

<style lang="scss">
.result-header {
  background-color: $accent;
  border-radius: $border-radius;
  color: $accent-inverse;
  text-transform: uppercase;
  font-weight: bold;
}

.border-right {
  border-right: solid 2px #FFFFFF !important;
}

.round-border {
  border-radius: 8px;
}

.no-result-found {
  font-size: 18px;
  font-weight: 500;
}
</style>