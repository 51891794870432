<template>
  <div>
    <b-row class="mb-4 p-10 midblue-text">
      <b-col v-bind:style="$store.getters.elementColourBlockText">
        <div class="text-center mb-4">
          <b-spinner class="m-5" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <h4 class="mb-4" align="center">{{$store.getters.quoteLoadingText}}</h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "quote-loading"
}
</script>

<style>

</style>