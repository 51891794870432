<template>
  <div class="result-item mb-4">
    <div class="result-item-border mb-1" v-bind:style="$store.getters.moreLessBorder">
      <b-row class="m-0 p-3">
        <b-col xl="4" lg="4" md="12" class="p-3 d-flex align-items-center">
          <div class="flex-grow-1">
            <div class="mb-2 text-center"><b-img fluid :src="product.ProviderLogoAbsoluteURL" class="insurer-logo"></b-img></div>
            <div class="product-name text-center font-weight-bolder">{{product.ProviderName}}</div>
            <b-col class="text-center">
              <a class="w-100 more-less zero-radius" v-if="!product.isActive" v-on:click="openDetail" v-bind:style="$store.getters.moreLessText">More Info<b-icon icon="caret-down-fill" font-scale="1"></b-icon></a>
              <a class="w-100 more-less zero-radius" v-if="product.isActive" v-on:click="closeDetail" v-bind:style="$store.getters.moreLessText">Less Info <b-icon icon="caret-up-fill" font-scale="1"></b-icon></a>
            </b-col>
          </div>
        </b-col>
        <b-col xl="4" lg="4" md="6" class="p-3 d-flex align-items-center border-lr" v-bind:style="$store.getters.moreLessBorder">
          <div class="flex-grow-1">
            <div class="result-underwriting font-weight-bold">{{product.ProductName}}</div>
            <div class="result-op font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">
            </div>
            <div class="flex-grow-1">
              <div>
                <div class="small-text"><b-icon icon="check-circle-fill" class="mr-2"></b-icon>Immediate cover available</div>
                <div class="small-text"><b-icon icon="check-circle-fill" class="mr-2"></b-icon>Policy trust service</div>
                <div class="small-text"><b-icon icon="check-circle-fill" class="mr-2"></b-icon>Online policy documents</div>  
              </div>
              <div class="pt-3 pb-2 mid-text">
                {{product.insurerDetail.lifeClaims}} CLAIMS PAID
              </div>
            </div>
          </div>
        </b-col>
        <b-col xl="4" lg="4" md="6" class="m-0 p-3 price-container text-center d-flex align-items-center" v-bind:style="$store.getters.optionBox">
          <div class="flex-grow-1">
            <div v-bind:style="$store.getters.writingColourBlockText">Monthly Premium</div>
            <div v-bind:style="$store.getters.writingColourBlockText" class="price-display font-weight-bold">£{{ product.Premium.toFixed(2) }}</div>
            <div class="flex-grow-1">
            <b-button  class="btn-apply w-75 mb-2" v-on:click="applyProduct(product)" variant="primary" v-bind:style="$store.getters.primaryButtonBox">{{ $store.getters.applyOnlineBtnText }}</b-button>
          </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="m-0 p-4" v-if="this.product.isActive">
        <b-col sm="12" class="benefit-border-right">
          <div class="text-justify" v-bind:style="$store.getters.moreLessText" v-html="product.insurerDetail.detail"></div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// var capitalize = require('capitalize');
import _ from "lodash";

export default {
  name: "quote-result-item",
  props: {
    product: Object
  },
  data() {
    return {
    
    }
  },
  computed: {
  },
  methods: {
    extractDeathBenefit: function(product){
      var benefits = false;
      if(product.hasOwnProperty('individualBenefits')){
        benefits = product.individualBenefits.ProtectionBenefitItem;
      }
      

      if(!benefits){
        return 0;
      } else {
        var benefitAmount = 0;
        if(benefits.length > 0){
          benefits.forEach(el => {
            if(el.CicType == 'None'){
              benefitAmount = el.BenefitAmount;
            }
          });
        }
        return benefitAmount;
      }
    },
    extractCriticalIllness: function(product){
      var benefits = false;
      if(product.hasOwnProperty('individualBenefits')){
        benefits = product.individualBenefits.ProtectionBenefitItem;
      }

      if(!benefits){
        return 0;
      } else {
        var criticalAmount = 0;
        if(benefits.length > 0){
          benefits.forEach(el => {
            if(el.CicType != 'None'){
              criticalAmount = el.BenefitAmount;
            }
          });
        }
        return criticalAmount;
      }
    },
    applyProduct: function(product){
      this.$store.commit("updateSelectedProtectionProduct", product);
      this.$store.commit("updateProtectionApplicationDetails", _.cloneDeep(this.$store.state.protection.quoteForm))
      this.$store.commit("updateProtectionQuoteRef");
      this.$router.push({ name: 'ProtectionQuoteApplication'});
    },
    openDetail: function(){
      this.product.isActive = true;
      this.$recordFrame();
      this.$emit('update:isViewMode', true);
      this.$resizeFrame();
      this.$forceUpdate();
    },
    closeDetail: function(){
      console.log('Tesfsdfsdf')
      this.$emit('update:isViewMode', false);
      this.product.isActive = false;
      this.$revertHeight();
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
.price-display {
  font-size:40px;
}

.result-item-border {
  border: solid 1px $light-blue;
  border-radius: 20px;
}

.cover-details {
  font-size: .8em;
}

.product-name {
  font-size: 1em;
}

.insurer-logo {
  max-width:80%;
  max-height: 80px;
}

.option-label {
  font-size: .8em !important;
  font-weight: bold;
  margin-bottom: 0px;
}

.option-label-btn {
  height: 28px;
  padding: 3px 15px;
  text-align: left;
  color: #000 !important;
}

.result-option {
  legend {
    padding-bottom: 2px;
  }

  select {
    height: 28px;
    padding: 3px 15px;
  }
}

.price-container {
  background: transparent !important;
}

.result-underwriting {
  font-size: 1.3em;
}

.result-op {
  font-size: 1.2em;
}

.rq-accordion {
  .card {
    background-color: transparent;
    border: none !important;

    .card-header {
      background-color: transparent;
    }
  }
}

.accordion-check {
  color: #A2CD8B;
}

.accordion-wrong {
  color: #FF0000;
}

.inpatient-icon {
  cursor: pointer;
}

.edit-hospital {
  font-size: .7em;
  cursor: pointer;
}


@media screen and (min-width: 1200px) {
  .benefit-border-right {
    border-right: solid 1px #F0F0F0;
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.promo-text {
  color: #ff0000;
}

.more-less {
  cursor: pointer;
}

.small-text {
  font-size: 11px;
}

.mid-text {
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .border-lr {
    border-left: none;
    border-right: solid 1px #000;
  }
}

@media (min-width: 992px) {
  .border-lr {
    border-left: solid 1px #000;
    border-right: solid 1px #000;
  }
}
</style>