<template>
  <div>
    <b-button variant="primary"
      v-if="!showTailorCover" 
      class="w-100 zero-radius mb-3 d-block d-sm-none" 
      @click="toggleTailorCover"
      v-bind:style="$store.getters.primaryButtonBox"
    >
      Tailor Your Cover<b-icon icon="caret-right-fill" font-scale="1"></b-icon>
    </b-button>
    <b-card 
      v-bind:class="{'d-none d-sm-block': !showTailorCover }"
      class="mb-4 quick-change-box" 
      v-bind:style="$store.getters.optionBox">
      <b-container class="p-1">
        <b-row>
          <b-col xl="6" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex flex-row v-align-center justify-content-start">
                  <div>
                    <span>
                      How much cover
                    </span>
                  </div>
                </div>
              </template>
              <b-input-group prepend="£" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="deathBenefitAmount" placeholder="Amount"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex flex-row v-align-center justify-content-start">
                  <div>
                    <span>
                      For how long
                    </span>
                  </div>
                </div>
              </template>
              <b-input-group append="years" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="term" placeholder="Years"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col xl="12" lg="12" md="12" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex v-align-center justify-content-start">
                  <div>
                    <span>
                      Type of cover
                    </span>
                  </div>
                </div>
              </template>
              <b-form-radio-group 
                class="rq-toggle w-100"
                v-model="quoteType"
                name="policy type"
                buttons
              >
                <b-form-radio
                  class="d-flex sm-font mb-3 justify-content-start" 
                  v-for="(item,i) in quoteTypeOptions" 
                  :disabled="askingMode == 'application'"
                  :key="i" 
                  :value="item.value" 
                  v-bind:style="[item.value == quoteType ? $store.getters.buttonBox : {}]"
                >
                  <template v-if="item.value == quoteType"><b-icon icon="check2-circle" class="mr-4"></b-icon></template>
                  <template v-if="item.value != quoteType"><b-icon icon="circle" class="mr-4"></b-icon></template>
                  <div class="d-flex flex-column text-left">
                    <div class="btn-label">{{item.text}}</div>
                    <div class="btn-description">{{item.description}}</div>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="12">
            <div class="font-weight-bold d-flex cursor-pointer" @click="toggleExtra" v-bind:style="$store.getters.elementColourBlockText">
              <div class="mr-4">Policy Extras</div>
              <div v-if="!isShowExtra"><b-icon-caret-down-fill /></div>
              <div v-if="isShowExtra"><b-icon-caret-up-fill /></div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="isShowExtra">
          <b-col xl="4" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <b-form-checkbox
                v-model="hasIndexation"
                name="indexation"
                value="1"
                unchecked-value="0"
              >
                Add indexation
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col xl="4" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <b-form-checkbox
                v-model="hasWaiverPremium"
                name="waiver"
                value="1"
                unchecked-value="0"
              >
                Add waiver premium
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col xl="4" lg="6" md="6" sm="12">
            <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <b-form-checkbox
                @change="onCriticalChange"
                v-model="hasCriticalIllness"
                name="criticalIllness"
                value="1"
                unchecked-value="0"
              >
                Add critical illness
              </b-form-checkbox>
            </b-form-group>
            
          </b-col>
          <b-col sm="12">
            <div class="d-flex flex-row v-align-center justify-content-start mb-4">
              <span class="not-sure" @click="() => showExtraInfo = !showExtraInfo" v-bind:style="$store.getters.infoBubble">What are these?</span>
            </div>
            <div v-bind:style="$store.getters.infoBubble" v-if="showExtraInfo" class="mt-3">
              <div class="font-weight-bolder">Indexation</div>
              <p>Opting for indexation implies that your coverage amount and premiums will increase annually in accordance with inflation. This feature is exclusively available for Family Cover under the Level Term policy.</p>

              <div class="font-weight-bolder">Waiver of Premium</div>
              <p>Waiver of Premium is an optional add-on that offers coverage in the event that you become unable to work due to an accident or illness. After a period of 6 months, your insurance provider will take on the responsibility of paying your premiums until you are fit to return to work.</p>

              <div class="font-weight-bolder">Critical Illness Cover</div>
              <p>Critical Illness Cover is an insurance policy that provides a payout in the event of a severe medical condition that is listed by your chosen insurer. Examples of such conditions may include a serious cancer diagnosis or heart attack.</p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group v-if="hasCriticalIllness && isShowExtra" class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
              <template v-slot:label>
                <div class="d-flex flex-row v-align-center justify-content-start">
                  <div>
                    <span>
                      How much critical illness
                    </span>
                  </div>
                </div>
              </template>
              <b-input-group prepend="£" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input v-model="criticalIllnessAmount" placeholder="Amount"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-if="$store.getters.hasCustomerAgreement && $store.getters.twoPageQuote">
              <p class="font-weight-bold mb-4" v-bind:style="$store.getters.elementColourBlockText">{{$store.getters.customerAgreeMessage || ""}}</p>
            </div>
            <b-button class="large-button" variant="primary" v-on:click="getProtectionQuotes" 
            v-bind:style="$store.getters.primaryButtonBox"
            >{{ $store.getters.twoPageQuote ? "Get Quote" : "Update Results" }}</b-button>
            <div v-if="$store.getters.twoPageQuote" class="mt-10">
              <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.privacyLink" :href="$store.getters.privacyLink" target="_blank" class="mr-5 font-weight-bold">Privacy Policy</a>
              <a v-bind:style="$store.getters.elementColourBlockText" v-if="$store.getters.tosLink" :href="$store.getters.tosLink" target="_blank" class="font-weight-bold">Terms and Condition</a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-button variant="primary" 
      v-if="showTailorCover" 
      class="w-100 zero-radius mb-3 d-block d-sm-none" 
      @click="toggleTailorCover"
      v-bind:style="$store.getters.primaryButtonBox"
    >
      Hide This<b-icon icon="caret-right-fill" font-scale="1"></b-icon>
    </b-button>
  </div>
  
</template>

<script>
export default {
  name: "quote-change",
  props: ['isViewMode'],
  data() {
    return {
      showExtraInfo: false,
      isShowExtra: false,
      showTailorCover: false,
      resizeConfig: {ratio: 0.8, minFontSize: '1px', maxFontSize: '14px', delay: 200},
      ynOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ],
      quoteTypeOptions: [
        { text: "Family Cover", value: "family", description: "(Level)" },
        { text: "Mortgage Cover", value: "mortgage", description: "(Decreasing)" },
      ],
    };
  },
  computed: {
    term: {
      get(){
        return this.quoteForm.term;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.term = value;
        this.updateDetails(quoteForm);
      }
    },
    deathBenefitAmount: {
      get(){
        return this.quoteForm.deathBenefitAmount;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.deathBenefitAmount = value;
        this.updateDetails(quoteForm);
      }
    },
    quoteType: {
      get(){
        return this.quoteForm.quoteType;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.quoteType = value;
        this.updateDetails(quoteForm);
      }
    },
    criticalIllnessAmount: {
      get(){
        return this.quoteForm.criticalIllnessAmount;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.criticalIllnessAmount = value;
        this.updateDetails(quoteForm);
      }
    },
    hasCriticalIllness: {
      get(){
        return this.quoteForm.benefitType == 'deathCic' ? 1 : 0;
      },
      set(value){
        if(parseInt(value) == 1){
          value = 'deathCic';
        } else {
          value = 'death';
        }
        console.log(value);
        let quoteForm = { ...this.quoteForm };
        quoteForm.benefitType = value;
        this.updateDetails(quoteForm);
      }
    },
    hasWaiverPremium: {
      get(){
        return this.quoteForm.hasWaiverPremium;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.hasWaiverPremium = parseInt(value);
        this.updateDetails(quoteForm);
      }
    },
    hasIndexation: {
      get(){
        return this.quoteForm.hasIndexation;
      },
      set(value){
        let quoteForm = { ...this.quoteForm };
        quoteForm.hasIndexation = parseInt(value);
        this.updateDetails(quoteForm);
      }
    },
    quoteForm: {
      get() {
        return this.$store.state.protection.quoteForm;
      }
    }
  },
  methods: {
    toggleExtra: function(){
      this.isShowExtra = !this.isShowExtra;
    },
    onCriticalChange: function(){
      if(this.hasCriticalIllness){
        this.criticalIllnessAmount = this.deathBenefitAmount;
      } else {
        this.criticalIllnessAmount = null;
      }
    },
    closeOtherPopover: function(){
      this.$root.$emit('bv::hide::popover')
    },
    updateSelectedExcess: function(){
      this.$store.commit("changeExcess");
      this.$store.commit("sortQuotes");
    },
    toggleTailorCover: function(){
      console.log("Toggle tailor cover");
      this.showTailorCover = !this.showTailorCover;
      this.$resizeFrame();
    },
    updateDetails: function(data){
      this.askingMode == "application" ? this.$store.commit("updateApplicationDetails", data) : this.$store.commit("updateQuoteForm", data);
      this.$resizeFrame();
    },
    fetchQuotes: function(){
      this.$store.commit("updateLoadingStatus", true);
      this.$store.dispatch("getProtectionQuoteProducts").then(quotes => {
        var pluginMessage = { 
          messageType: "quoteResults",
          payload: {
            clientDetails: this.$formatProtection(this.quoteForm),
            quoteResult: quotes
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        // this.$router.push({ name: 'ProtectionQuoteResult' });
        this.$resizeFrame();
        this.$store.commit("updateLoadingStatus", false);
      })
      .catch(() => {
        this.$store.commit("updateLoadingStatus", false);
      });
    },
    getProtectionQuotes: function(){
      console.log("This called");
      var _this = this;
      this.$resetFrame();
      this.$store.commit("updateLoadingStatus", true);
      this.$emit('update:isViewMode', false);

      this.$validator.validateAll().then(result => {
        if(result){
          this.$store.commit("updateViewMode", false);
          this.$store.commit("updateEditMode", false);
          
          if(!this.$store.getters.twoPageQuote){
            // Check if form has text validation
            this.$store.dispatch('hasVerification', { phoneNumber: this.telephoneNumber }).then(res => {
              console.log(res);
              if(res.data.hasTextVerification){
                this.otpReference = res.data.reference;

                // Fetch quotes on background
                this.fetchOtpQuotes();

                this.$swal.fire({
                  position: 'top',
                  showClass: {
                    popup: 'swal2-show mt-25',
                  },
                  title: 'Verification',
                  html: `Enter the OTP code you have received.<br/><small>Code expires in 5 minutes.</small>`,
                  input: 'number',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Verify',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showDenyButton: true,
                  denyButtonText: 'Resend Code',
                  denyButtonColor: '#369A6E',
                  didOpen: () => {
                    this.isOtpWaiting = true;
                  },
                  preDeny: () => {
                    return new Promise(function(){
                      _this.$store.dispatch('hasVerification', { phoneNumber: _this.telephoneNumber, reference: _this.otpReference }).then(res => {
                        _this.$swal.enableButtons();
                        _this.otpReference = res.data.reference;
                      });
                    });
                  },
                  preConfirm: (otp) => {
                    return new Promise(function(resolve){
                      _this.$store.dispatch('verifyOtp', {
                        otp: otp,
                        reference: _this.otpReference
                      }).then(response => {
                        if(response.data.success){
                          resolve();
                          _this.$router.push({ name: 'PMIQuoteResult' });
                          _this.$store.commit("updateLoadingStatus", false);
                        } else {
                          _this.$swal.showValidationMessage("Invalid OTP code.");
                          _this.$swal.enableButtons();
                        }
                      });
                    });
                  }
                }).then((result) => {
                  if(result.dismiss == 'cancel'){
                    this.isOtpWaiting = false;
                    this.$store.commit("updateQuoteResult",[]);
                  }
                });
              } else {
                this.fetchQuotes(); 
              }
            }).catch(err => {
              console.log(err);
              if(this.$store.state.protection.displayMode == 'demo'){
                this.fetchQuotes();
              }
            });
          } else {
            this.$router.push({ name: 'ProtectionQuoteResult' });
            this.$store.commit("updateLoadingStatus", false);
          }
          
        }
      });
    },
  },
  created(){
    if(this.$store.getters.twoPageQuote){
      this.showTailorCover = true;  
    }

    document.getElementsByTagName('body')[0].addEventListener('click', () => {
      this.$root.$emit('bv::hide::popover')
    });
  }
}
</script>

<style lang="scss">
.quote-popover {
  min-width: 200px;

  .popover-body {
    padding: 0;
  }

  .popover-highlight {
    width: 100%; 
    height: 5px; 
    background-color: #FF0000; 
    border-radius: 5px 5px 0 0;
    margin-top: -1px;
  }
}

.btn-qc {
  height: 30px !important;
  padding: 3px 5px !important;
  width: 130px;
  font-size: 10px !important;
  margin-bottom: 5px !important;
}

.select-qc {
  height: 30px !important;
  padding-top: 5px;
  padding-top: 5px;
}

.btn-qc-sm {
  width: 120px;
}

.quick-change-box {
  .card-body {
    padding: 15px;
  }
}


.btn-qc.btn-secondary.focus:not(.btn-text){
  background-color: transparent;
}

.popover-icon {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-bolder {
  font-weight: bold;
}
</style>
