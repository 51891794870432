<template>
    <div class="edit-cover pt-15">
      <div class="reset-quote d-flex align-items-center" v-on:click="closeEdit">
        <div class="mr-5 font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">Back to Quote Results</div>
        <b-iconstack font-scale="2">
          <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.elementColourBlockText"></b-icon>
          <b-icon stacked icon="caret-left-fill" variant="light"  scale="0.5"></b-icon>
        </b-iconstack>
      </div>
      <div class="result-item mb-4">
        <div class="result-item-border mb-1" v-bind:style="$store.getters.moreLessBorder">
          <div v-if="!isLoading">
            <b-row class="m-0 p-0">
              <b-col xl="2" lg="4" md="6" class="p-3 d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="mb-2 text-center"><b-img fluid :src="logo" class="insurer-logo"></b-img></div>
                  <div class="product-name text-center font-weight-bolder">{{this.product.product}}</div>
                </div>
              </b-col>
              <b-col xl="2" lg="4" md="6" class="p-3 d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="result-underwriting font-weight-bold">{{ product.underwritingLabel }}</div>
                  <div class="result-op font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">
                    {{product.opLevel}}
                  </div>
                </div>
              </b-col>
              <b-col xl="2" lg="4" md="6" class="m-0 p-3 price-container text-center d-flex align-items-center" v-bind:style="$store.getters.optionBox">
                <div class="flex-grow-1">
                  <div v-bind:style="$store.getters.writingColourBlockText">Per month</div>
                  <div v-bind:style="$store.getters.writingColourBlockText" class="price-display font-weight-bold">£{{ product.selectedPrice }}</div>
                  <div v-bind:style="$store.getters.writingColourBlockText">*Indicative price</div>
                </div>
              </b-col>
              <b-col xl="2" lg="4" md="6" class="p-3 d-flex align-items-center">
                <div class="flex-grow-1">
                  <b-form-group class="result-option mb-2">
                    <template v-slot:label>
                      <label class="option-label font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">Excess/Co Payment</label>
                    </template>
                    <b-form-select size="sm" v-bind:style="$store.getters.elementColourBlockBorder" v-model="product.selectedPrice" :options="product.excessOptions"></b-form-select>
                  </b-form-group>
                  <b-form-group class="result-option mb-2">
                    <template v-slot:label>
                      <label class="option-label font-weight-bold" v-bind:style="$store.getters.elementColourBlockText">Hospital List</label>
                    </template>
                    <div class="d-flex">
                      <div class="font-weight-bold" v-bind:style="$store.getters.writingColourBlockText">{{ product.selectedHospital }}</div>
                    </div>
                  </b-form-group>
                </div>
              </b-col>
              <b-col xl="2" lg="4" md="6" class="cover-details p-3 d-flex align-items-center">
                <div class="flex-grow-1">
                  <b-row>
                    <b-col cols="8">Inpatient:</b-col>
                    <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                      {{product.coverDetails.inpatient.covered ? "Yes" : "No"}}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="5">Outpatient:</b-col>
                    <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                      {{product.coverDetails.outpatient.level}}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="8">Cancer Cover:</b-col>
                    <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                      {{product.coverDetails.heart_cancer.covered ? "Yes" : "NHS"}}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="8">Mental Health:</b-col>
                    <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                      {{product.coverDetails.mental.covered ? "Yes" : "No"}}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="8">Therapies:</b-col>
                    <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                      {{product.coverDetails.therapy.covered ? "Yes" : "No"}}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="8">Dental/Optical:</b-col>
                    <b-col class="text-right font-weight-bolder" v-bind:style="$store.getters.elementColourBlockText">
                      {{product.coverDetails.therapy.dental ? "Yes" : "No"}}
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col xl="2" lg="4" md="6" class="p-3 align-items-center d-flex">
                <div class="flex-grow-1">
                  <b-button class="btn-apply w-100 mb-2" v-on:click="applyProduct(product)" variant="primary" v-bind:style="$store.getters.primaryButtonBox">{{ $store.getters.applyOnlineBtnText }}</b-button>
                  <b-button class="btn-apply w-100" v-if="!product.isActive" v-on:click="openDetail" variant="primary" v-bind:style="$store.getters.primaryButtonBox">More Info</b-button>
                  <b-button class="btn-apply w-100" v-if="product.isActive" v-on:click="closeDetail" variant="primary" v-bind:style="$store.getters.primaryButtonBox">Less Info</b-button>
                </div>
              </b-col>
            </b-row>
            <transition name="slide-fade">
            <b-row class="m-0 p-3" v-if="product.isActive">
                <b-col xl="6" class="benefit-border-right">
                  <div class="d-flex flex-row v-align-center justify-content-start mb-3">
                    <div class="pl-2 font-weight-bolder">
                      <span>Day/Inpatient</span>
                    </div>
                    <div class="ml-4 inpatient-icon" :id="`inpatient-detail`">
                      <b-iconstack font-scale="1.5">
                        <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                        <b-icon stacked icon="info" variant="light"  scale="0.9"></b-icon>
                      </b-iconstack>
                    </div>
                  </div>
                  <b-popover
                    ref="popover"
                    :target="`inpatient-detail`"
                    triggers="click"
                    @show="closeOtherPopover"
                    custom-class="quote-popover"
                  >
                    <template #title></template>
                    <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                    <div class="p-3">
                      <p class="mb-0"><strong>Day/Inpatient</strong> – Covers any treatment related to staying overnight or a few hours in hospital. This could be surgeon fees, diagnostic tests such as blood tests, x-rays or ultrasounds as part of the treatment.</p>
                    </div>
                  </b-popover>
                  <div class="accordion rq-accordion" id="inpatient-accordion" role="tablist">
                    <template v-for="(keyFeature, i) in product.keyFeatures.inpatient">
                    <b-card no-body class="mb-1" :key="i">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-row>
                          <b-col v-b-toggle="`inpatient-accordion-${i}`">
                            <b-icon v-if="!keyFeature.hasOwnProperty('excluded')" icon="check" class="mr-3 accordion-check" font-scale="1"></b-icon>
                            <b-icon v-if="keyFeature.hasOwnProperty('excluded')" icon="x" class="mr-3 accordion-wrong" font-scale="1"></b-icon>
                            <span>{{keyFeature.section}}</span>
                            <font-awesome-icon icon="angle-down" class="ml-5" />
                          </b-col>
                        </b-row>
                        
                      </b-card-header>
                      <b-collapse :id="`inpatient-accordion-${i}`" accordion="inpatient-accordion" role="tabpanel">
                        <b-card-body>
                          <b-card-text v-bind:style="$store.getters.writingColourBlockText">{{keyFeature.text}}</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    </template>
                  </div>
                </b-col>
                <b-col xl="6">
                  <div class="d-flex flex-row v-align-center justify-content-start mb-3">
                    <div class="pl-2 font-weight-bolder">
                      <span>Outpatient</span>
                    </div>
                    <div class="ml-4 inpatient-icon" :id="`outpatient-detail`">
                      <b-iconstack font-scale="1.5">
                        <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                        <b-icon stacked icon="info" variant="light"  scale="0.9"></b-icon>
                      </b-iconstack>
                    </div>
                  </div>
                  <b-popover
                    ref="popover"
                    :target="`outpatient-detail`"
                    triggers="click"
                    @show="closeOtherPopover"
                    custom-class="quote-popover"
                  >
                    <template #title></template>
                    <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                    <div class="p-3">
                      <p><strong>Specialist Fees</strong> – Covers the cost of seeing a consultant.</p>
                      <p><strong>Diagnostic Tests</strong> – Covers initial and follow up blood tests, X-rays and ultrasounds.</p>
                      <p><strong>MRI/CT/PET</strong> – These larger scans can be covered as part of an outpatient benefit.</p>
                      <p class="mb-0"><strong>Physiotherapy</strong> – Outpatient Physiotherapy sessions, these are limited based on certain outpatient levels.</p>
                    </div>
                  </b-popover>
                  <div class="accordion rq-accordion" id="outpatient-accordion" role="tablist">
                    <template v-for="(keyFeature, i) in product.keyFeatures.outpatient">
                    <b-card no-body class="mb-1" :key="i">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-row>
                          <b-col v-b-toggle="`outpatient-accordion-${i}`">
                            <b-icon v-if="!keyFeature.hasOwnProperty('excluded')" icon="check" class="mr-3 accordion-check" font-scale="1"></b-icon>
                            <b-icon v-if="keyFeature.hasOwnProperty('excluded')" icon="x" class="mr-3 accordion-wrong" font-scale="1"></b-icon>
                            <span>{{keyFeature.section}}</span>
                            <font-awesome-icon icon="angle-down" class="ml-5" />
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <b-collapse :id="`outpatient-accordion-${i}`" accordion="outpatient-accordion" role="tabpanel">
                        <b-card-body>
                          <b-card-text v-bind:style="$store.getters.writingColourBlockText">{{keyFeature.text}}</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    </template>
                  </div>
                </b-col>
            </b-row>
            </transition>
          </div>
          <div v-if="isLoading">
            <b-row class="mb-4 p-10 midblue-text">
              <b-col v-bind:style="$store.getters.elementColourBlockText">
                <div class="text-center mb-4">
                  <b-spinner class="m-5" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
                </div>
                <h4 class="mb-4" align="center">{{$store.getters.quoteLoadingText}}</h4>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-card class="quick-change-box">
        <b-container>
          <div class="d-flex justify-content-between flex-wrap">
            <template v-for="(opt,i) in productOptions">
              <div v-bind:key="i" v-if="!opt.condition || opt.condition.value.includes(updatedParams[opt.condition.key])" class="pl-2 pr-2">
                <b-form-group class="midblue-text strong" v-bind:style="$store.getters.elementColourBlockText">
                  <template v-slot:label>
                    <div class="d-flex flex-row v-align-center justify-content-start">
                      <div>
                        <span>
                          {{opt.label}}
                          <div v-if="opt.hasOwnProperty('infoBubble')" class="ml-1 d-inline-block popover-icon" :id="`tailor-cover-popover-${i}`">
                            <b-iconstack font-scale="1.5">
                              <b-icon stacked icon="circle-fill" v-bind:style="$store.getters.infoBubble"></b-icon>
                              <b-icon stacked icon="question" variant="light"  scale="0.9"></b-icon>
                            </b-iconstack>
                          </div>
                        </span>
                      </div>
                      
                    </div>
                    <b-popover
                      v-if="opt.hasOwnProperty('infoBubble')"
                      ref="popover"
                      :target="`tailor-cover-popover-${i}`"
                      triggers="click"
                      @show="closeOtherPopover"
                      custom-class="quote-popover"
                    >
                      <template #title></template>
                      <div class="popover-highlight" v-bind:style="$store.getters.infoBubbleBackground"></div>
                      <div v-html="opt.infoBubble.content" class="p-3">
                        <!-- Content goes here -->
                      </div>
                    </b-popover>
                  </template>
                  
                  <b-form-select
                    v-if="opt.options.length > 3"
                    class="select-tailor"
                    v-model="updatedParams[opt.model]"
                    :options="opt.options"
                  ></b-form-select>
                  
                  
                  <b-form-radio-group
                    v-if="opt.options.length <= 3"
                    class="rq-toggle d-flex flex-row flex-wrap"
                    v-model="updatedParams[opt.model]"
                    buttons
                  >
                    <b-form-radio
                      v-for="(item,i) in opt.options" 
                      :key="i" 
                      :value="item.value"
                      class="btn btn-secondary btn-tailor flex-grow-0 d-flex justify-content-center align-items-center"
                      v-bind:style="[item.value == updatedParams[opt.model] ? $store.getters.buttonBox : {}]"
                    >{{item.text}}</b-form-radio>
                  </b-form-radio-group>



                </b-form-group>
              </div>
            </template>
          </div>
          <b-row>
            <b-col align="center">
              <b-button class="large-button" variant="primary" v-on:click="updateProduct" v-bind:style="$store.getters.primaryButtonBox">Update Product</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </div>
</template>

<script>
// var capitalize = require('capitalize');
import _ from "lodash";
const addOns = require('../../../../../productOptions.json');

export default {
  name: "tailor-cover",
  data() {
    return {
      isLoading: false,
      dummyKeyFeatures: [
        'Lorem ipsum dolor sit amet',
        'Maecenas interdum est ac',
        'In id aliquam erat, eget'
      ],
      updatedParams: {
        quoteType: 'edit',
        dental: 0,
        therapy: 0,
        psychiatric: 0,
        travel: 0,
        heartAndCancer: 0,
        sixWeek: 0,
        pncd: 0,
        hospital: "local",
        vitalitytDental: 1,
        vitalityHospitalRate: "local",
        vitalityFullDiagnostics: 0,
        extendedCover: 0,
        guidedOption: 0,
        travelType: 15,
        axaConsent: 0,
        axaNhsCover: 0,
        excessType: 0,
        specificExcess: 0,
        preExistingCondition: 0,
        avivaHospitalRate: "none",
        bupaCancerCover: 2,
        bupaTherapy: 0,
        bupaDental: 0,
        bupaTravel: 0,
        dheLevel: 10,
        exeterFullDiagnostics: 0,
        ncdRequest: 1,
        ncdUplift: 0,
        worlwide: 0,
        coverageLevel: -1,
        treatmentLevel: -1,
        modifiedHospitalRate: "",
        modifiedTravel: 0,
        wpaCancer: 0,
        worldwide: 0,
        vitalityWorldwide: 0,
        modifiedOp: -1,
        nfOpExcess: 0,
        productLevel: 2
      }
    }
  },
  computed: {
    logo: {
      get() {
        return this.product.logo || "";
      }
    },
    price: {
      get() {
        return this.product.price.excess0 || 0.0;
      }
    },
    includedCover: {
      get() {
        return [];
      }
    },
    excludedCover: {
      get() {
        return [];
      }
    },
    product: {
      get() {
        return this.$store.state.protection.selectedProduct;
      }
    },
    productOptions: {
      get(){
        var matchedOptions = {
          addOns: []
        }
        addOns.forEach(opt => {
          if(opt.providerId == this.product.providerId){
            if(opt.productName == "ALL"){
              matchedOptions = opt;
            } else if(this.product.product.includes(opt.productName)) {
              matchedOptions = opt;
            }
          }
        });

        // Option override
        if(this.product.providerId == 22 && this.updatedParams.underwriting == "CPME"){
          var prodOpts = _.cloneDeep(matchedOptions['addOns']);
          
          prodOpts = prodOpts.map(opt => {
            if(opt.label == "Outpatient"){
              opt.options.shift();
            }
            return opt;
          });

          console.log(prodOpts);
          return prodOpts;
        } else {
          return matchedOptions['addOns'];
        }
      }
    }
  },
  methods: {
    closeOtherPopover: function(){
      this.$root.$emit('bv::hide::popover')
    },
    applyProduct: function(product){
      product.excessOptions.forEach(ex => {
        if(ex.value == product.selectedPrice){
          product['selectedExcess'] = ex.text;
        }  
      });

      product["coverageLevel"] = this.updatedParams.coverageLevel;
      product['startDate'] = this.updatedParams.coverStartDate;

      this.$store.commit("updateSelectedProduct", product);
      this.$store.commit("updateApplicationDetails", this.updatedParams);
      this.$store.commit("updateQuoteRef","edit");
      this.$router.push({ name: 'PMIQuoteApplication'});
    },
    closeEdit: function(){
      this.$resetFrame();
      this.closeDetail();
      this.$emit("update:isEditMode", false);
      this.$resizeFrame();
    },
    updateProduct: function(){
      this.isLoading = true;

      this.$store.dispatch("getQuoteProduct", this.updatedParams).then(quote => {
        this.isLoading = false;

        var pluginMessage = { 
          messageType: "updateResult",
          payload: {
            clientDetails: this.$formatPmi(this.updatedParams),
            quoteResult: [quote]
          }
        };

        // Transmit to parent window.
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");
      });
    },
    openDetail: function(){
      this.$recordFrame();
      this.$emit('update:isViewMode', true);
      this.product.isActive = true;
      this.$resizeFrame();
    },
    closeDetail: function(){
      this.$emit('update:isViewMode', false);
      this.product.isActive = false;
      this.$revertHeight();
    },
    preloadValues: function(){
      this.updatedParams = Object.assign({}, this.updatedParams, _.cloneDeep(this.$store.state.protection.quoteForm));

      var coverage = this.product.coverDetails.outpatient.level;
      var treatment = this.product.coverDetails.therapy.level;
      var treatmentCovered = this.product.coverDetails.therapy.covered;
      
      var coverageAmount = this.product.coverDetails.outpatient.amount;
      var treatmentAmount = this.product.coverDetails.therapy.amount;

      console.log(coverage);
      console.log(coverageAmount);

      if(this.updatedParams.coverage == 'basic'){
        this.updatedParams.bupaCancerCover = 2;
      } else if(this.updatedParams.coverage == 'intermediate' || this.updatedParams.coverage == 'full'){
        this.updatedParams.bupaCancerCover = 3;
      }

      if(coverage == "No Outpatient"){
        this.updatedParams.coverage = "basic";
        this.updatedParams.coverageLevel = 1;
      } else if(coverage == "Limited"){
        this.updatedParams.coverage = "intermediate";

        switch(coverageAmount){
          case '£500':
            this.updatedParams.coverageLevel = 2.1;
            break;
          case '£750':
            this.updatedParams.coverageLevel = 2.3;
            break;
          case '£350':
          case '£1000':
            this.updatedParams.coverageLevel = 2.2;
            break;
          case '£1250':
            this.updatedParams.coverageLevel = 2.5;
            break;
          case '£1500':
            this.updatedParams.coverageLevel = 2.4;
            break;
          case '£2000':
            this.updatedParams.coverageLevel = 2.6;
            break;
          case '£5000':
            this.updatedParams.coverageLevel = 2.7;
            break;
          default:
            this.updatedParams.coverageLevel = 1;
            break;
        }
      } else if(coverage == 'Full')  {
        this.updatedParams.coverage = "full";
        this.updatedParams.coverageLevel = 3;
      }

      if(treatment == "Not Applicable"){
        if(treatmentCovered){
          this.updatedParams.treatment = 1;
          this.updatedParams.treatmentLevel = 3;
        } else {
          this.updatedParams.treatment = 0;
          this.updatedParams.treatmentLevel = 0;
        }
      } else if(treatment == "Limited"){
        this.updatedParams.treatment = 1;

        switch(treatmentAmount){
          case '£500':
            this.updatedParams.treatmentLevel = 1;
            break;
          case '£750':
            this.updatedParams.treatmentLevel = 4;
            break;
          case '£1000':
            this.updatedParams.treatmentLevel = 2;
            break;
          case '£1500':
            this.updatedParams.treatmentLevel = 5;
            break;
          default:
            this.updatedParams.treatmentLevel = 3;
            break;
        }
      } else if(treatment == 'Full')  {
        this.updatedParams.treatment = 1;
        this.updatedParams.treatmentLevel = 3;
      }

      if(this.updatedParams.hospital == 'countrywide'){
        this.updatedParams.avivaHospitalRate = "standard";
        this.updatedParams.vitalityHospitalRate = "standard";
      } else if(this.updatedParams.hospital == 'countrywide_plus'){
        this.updatedParams.avivaHospitalRate = "extended";
        this.updatedParams.vitalityHospitalRate = "extended";
      }

      if(this.updatedParams.guidedOption){
        this.updatedParams.avivaHospitalRate = "expert";
        this.updatedParams.vitalityHospitalRate = "consultant";
      }
    }
  },
  mounted(){
    this.preloadValues();
  },
  created(){
    document.getElementsByTagName('body')[0].addEventListener('click', () => {
      this.$root.$emit('bv::hide::popover')
    });
  }
};
</script>

<style lang="scss">
.pmi-result-item {
  border: 1px solid $accent;
  border-radius: $border-radius;
}

.insurer-logo {
  max-width: 90%;
}

.price-display {
  font-size:30px;
}

.btn-apply {
  font-weight: 700;
}

.product-details {
  font-size: 17px;
  font-weight: 600;
}

.border-separator {
  border-left: solid 2px #ACACAC;
}

.product-detail-bg {
  background-color: #E5E6F3;
}

.product-detail-title {
  font-weight: 600;
  font-size: 17px;
}

.product-detail-list {
  font-size: 15px;
}

.result-item-border {
  border: solid 1px $light-blue;
}

.edit-cover {
  .list-group-item {
    background-color: transparent;
    border: none;
    padding: 7px;
    font-size: 14px;
    font-weight: 700;
  }

  position: relative;
}

.translucent-result {
  opacity: 0.4;
  pointer-events: none;
}

.reset-quote {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.btn-tailor {
  height: 30px !important;
  padding: 3px 5px !important;
  min-width: 90px;
  font-size: 10px !important;
  margin-bottom: 5px !important;
}

.select-tailor {
  height: 30px !important;
  padding: 5px 15px !important;
  min-width: 140px;
  font-size: 12px !important;
  margin-bottom: 5px !important;
}

.select-tailor {
  height: 30px !important;
  padding-top: 5px;
  padding-top: 5px;
}

@media screen and (min-width: 1200px) {
  .benefit-border-right {
    border-right: solid 1px #F0F0F0;
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.popover-icon {
  cursor: pointer;
}
</style>
